.inter{
  font-family: "Inter", sans-serif !important;
}
.work-sans{
  font-family: "Work Sans", sans-serif !important;
}
html,body{
  background: white;
  position: relative;
}
*{
  padding: 0;
  margin: 0;box-sizing: border-box;
}
.swiper-button-prev{
left:0 !important;
/* font-size: 12px !important; */
/* width: 200px !important; */
}.swiper-button-next{
  right:0 !important;}
  .bg-img {
    background: linear-gradient(
        rgba(255, 255, 255, 0.9), /* Transparent white overlay */
        rgba(255, 255, 255, 0.9)
      ),
      url("./assets/images/world.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }
  
  @keyframes slide {
    0% {
      left:600px;
    }
    100% {
      left: -600px;
    }
  }
  
  .slide-1 {
 /* Ensure element can move with 'left' */
    animation-name: slide;
    animation-duration: 10s; /* Add time unit (e.g., 5 seconds) */
    animation-timing-function: linear; /* Smooth transition curve */
    animation-iteration-count: infinite; /* Loop infinitely */
    animation-direction: normal; /* Default direction (can add reverse if needed) */
  }
  @keyframes slide2 {
    0% {
      left:600px;
    }
    100% {
      left: -600px;
    } 
  }

   
  .slide-2 {
 /* Ensure element can move with 'left' */
    animation-name: slide2;
    animation-duration: 10s; /* Add time unit (e.g., 5 seconds) */
    animation-timing-function: linear; /* Smooth transition curve */
    animation-iteration-count: infinite; /* Loop infinitely */
    animation-direction: normal;
    animation-delay: 5s;
    /* animation-delay: 10s; Default direction (can add reverse if needed) */
  }
.food-cont{
  background-repeat:  no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("./assets/images/cattering.avif");
}
.resource{
  background-repeat:  no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("./assets/images/backgroundgrains.png");
}
.women_group{
  background-repeat:  no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("./assets/images/womengroup.avif");
}
.business{
  background-repeat:  no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("./assets/images/business.avif");
}

/* .dropdown-profile{
  position: relative;
  left:500px;

} */