@tailwind base;
@tailwind components;
@tailwind utilities;

.links_hover {
  @apply after:w-full after:h-[2px] after:bg-cyan-blue after:absolute after:left-0 after:bottom-[-2px] after:scale-x-0 hover:after:scale-x-100 after:transition-all after:duration-500;
}

.sm_nav {
  @apply md:flex-row md:justify-start justify-center flex-col;
}

